import React, { useContext } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"

import Button from "elements/Button"
import MedicineInfo from "./MedicineInfo"
import TotalPriceInfo from "./TotalPriceInfo"
import FormInput from "elements/Form/FormInput"
import MedicineQuantityInput from "./MedicineQuantityInput"

import { medicineModalValidationSchema } from "../utils/deliveryDetailsFormSchema"
import { AppContext } from "../../../context/AppContext"
import styles from "../utils/epharmacy.module.scss"
import { changeQuantity } from "../services/cart"

const AddMedicineModal = ({ addToCart, medicine }) => {
  const { dispatch } = useContext(AppContext)

  return (
    <Formik
      initialValues={{ dailyIntake: "", otherDailyIntake: "", qty: 1 }}
      validationSchema={medicineModalValidationSchema}
      onSubmit={values => {
        let intake = `${values.dailyIntake}${
          !!values.dailyIntake & !!values.otherDailyIntake ? `, ` : ``
        }${values.otherDailyIntake ? `${values.otherDailyIntake}` : ``}`
        dispatch({ type: "HIDE_MODAL" })
        if (addToCart) addToCart(medicine, values.qty, intake)
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mx-4 mx-1-mobile">
            <MedicineInfo medicine={medicine} />
            <MedicineQuantityInput
              changeQuantity={changeQuantity}
              setFieldValue={setFieldValue}
              qty={values.qty}
            />
            <hr className="has-background-light my-0" />
            <TotalPriceInfo medicine={medicine} qty={values.qty} />
            <FormInput
              name="dailyIntake"
              label="Daily Intake"
              className={classNames("has-text-centered", styles["numberInput"])}
              type="number"
              min={1}
              max={2}
              helper="How many are you taking per day?"
              hideOptional
              hasAddons={true}
              addonRight={<Button className="is-static"> per day </Button>}
            />
            <FormInput
              name="otherDailyIntake"
              label="Others"
              helper="Let us know if you take the meds irregularly."
              placeholder="e.g. pill-split, every other day, as needed"
              type="text"
              hideOptionalhandleClick
            />
            <button
              type="submit"
              className="button is-fullwidth is-primary mt-2 mb-2"
              disabled={values.qty < 1}
            >
              Add to cart
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddMedicineModal
