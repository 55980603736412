import React from "react"
import { generatePrice } from "../services/computations"

const TotalPriceInfo = ({ medicine, qty }) => (
  <p className="has-text-right">
    Total:{" "}
    <span className="has-text-primary has-text-weight-bold">
      Php{" "}
      {parseFloat((generatePrice(medicine) * qty).toFixed(2)).toLocaleString(
        "en-US",
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }
      )}
    </span>
  </p>
)

export default TotalPriceInfo
