import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Container from "layout/Container"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"

import {
  GATSBY_EPHARMACY_KEY,
  GATSBY_EPHARMACY_VALUE,
} from "gatsby-env-variables"
import { AppContext } from "../../context/AppContext"

const PatientEnroll = ({ location }) => {
  const { dispatch } = useContext(AppContext)
  let epharmacyType = location?.state?.epharmacyType

  const handleSubmit = () => {
    sessionStorage.setItem(GATSBY_EPHARMACY_KEY, GATSBY_EPHARMACY_VALUE)
    navigate("/epharmacy/order", { state: { epharmacyType: epharmacyType } })
  }

  useEffect(() => {
    dispatch({
      type: "RESET_STATE",
    })
  }, [dispatch])

  return (
    <Layout
      title="Order Medicines"
      subtitle="Please provide consent to the terms and conditions before proceeding."
      seoTitle="Order Medicines"
    >
      <Container isCentered>
        <ConsentAndAuthorization
          cta="Order Medicines"
          app="hope"
          hasActionLinks={true}
          handleOnSubmit={handleSubmit}
        >
          <p>
            By submitting my order, I acknowledge that I agree to the terms and
            conditions above.
          </p>
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default PatientEnroll
