import React from "react"
import { Link } from "gatsby"

import Section from "elements/Section"
import Message from "elements/Message"
import EditDetailsButton from "elements/EditDetailsButton"
import MedicineInfoRow from "./MedsOrderedSummary/MedicineInfoRow"

const MedsOrderedSummary = ({ medicines }) => {
  return (
    <Section
      title={`Medicines Ordered (${medicines.length})`}
      isRequired
      addOns={{
        right: <EditDetailsButton route="/epharmacy/order" />,
      }}
    >
      {medicines.length < 1 ? (
        <Message color="warning">
          <p className="is-size-6 has-text-dark">
            You have not added any medicines.{" "}
            <Link to="/epharmacy/order">Add Medicines</Link>
          </p>
        </Message>
      ) : (
        <div className="px-1">
          {medicines.map(medicine => (
            <MedicineInfoRow medicine={medicine} />
          ))}
        </div>
      )}
    </Section>
  )
}
export default MedsOrderedSummary
