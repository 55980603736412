import { Link } from "gatsby"
import React from "react"

import SEO from "layout/SEO"
import Layout from "layout/Layout"
import Container from "layout/Container"

import { mechanicsImportantNotes } from "./utils/blisterMechanicsNotes"

const BlisterMechanics = () => {
  return (
    <Layout title={<span>Blister Ordering Mechanics</span>}>
      <SEO title="Blister Form Mechanics" />
      <Container isCentered customClassName="content">
        <p>
          To order medicines by the blister, you must be enrolled in the HOPE
          program, prescribed with the medicines covered in the program, and
          have been granted financial assistance. You must also have received an
          SMS confirming your eligibility to order by the blister.
        </p>
        <h4 className="is-size-4">Important Notes</h4>
        <p>
          <ol className="is-size-5" type="a">
            {mechanicsImportantNotes.map(note => (
              <li className="pl-1">{note}</li>
            ))}
          </ol>
        </p>
        <Link
          className="button is-primary is-fullwidth is-medium my-3"
          to="/epharmacy"
          state={{ epharmacyType: "blister" }}
        >
          Continue
        </Link>
      </Container>
    </Layout>
  )
}

export default BlisterMechanics
