import React from "react"
import classNames from "classnames"
import FormInput from "elements/Form/FormInput"

export const generateFollowUp = ({ followUp }) => {
  switch (followUp.followUpType) {
    case "input":
      return (
        <FormInput
          {...followUp}
          addonLeft={
            followUp?.hasAddons && (
              <p class="control">
                <span class="button is-static">{followUp?.addonLeft}</span>
              </p>
            )
          }
        />
      )
    case "message":
      return (
        <p className={classNames("is-size-6", followUp.className || "")}>
          {followUp?.message}
        </p>
      )
    default:
      return null
  }
}
