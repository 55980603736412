import React from "react"
import { Link } from "gatsby"

import Section from "elements/Section"
import FileThumbnail from "./UploadedDocumentsSummary/FileThumbnail"
import EditDetailsButton from "elements/EditDetailsButton"
import Message from "elements/Message"

const UploadedDocumentsSummary = ({ files, route }) => (
  <Section
    title={`Documents Uploaded (${files.length})`}
    addOns={{
      right: <EditDetailsButton route={route} />,
    }}
    isRequired
  >
    {files.length < 1 ? (
      <Message color="warning">
        <p className="is-size-6 has-text-dark">
          You have not uploaded any documents.{" "}
          <Link to={route || "/epharmacy/upload"}>Upload Documents</Link>
        </p>
      </Message>
    ) : (
      <div className="px-1 mx-1">
        <div className="columns is-vcentered is-multiline">
          {files.map(item => (
            <div className="column is-6">
              <FileThumbnail file={item} hasFileName />
            </div>
          ))}
        </div>
      </div>
    )}
  </Section>
)

export default UploadedDocumentsSummary
