import React from "react"
import classNames from "classnames"

import styles from "../../utils/epharmacy.module.scss"

const RadioContainerButton = ({ isSelected }) => (
  <div className={classNames(styles["shippingDetails__radioButton"])}>
    {isSelected && (
      <div
        className={classNames(styles["shippingDetails__radioButtonChecked"])}
      ></div>
    )}
  </div>
)

export default RadioContainerButton
