import React from "react"
import classNames from "classnames"

import SearchResultRow from "./SearchResults/SearchResultRow"

import styles from "./utils/search.module.scss"

const SearchResults = ({
  searchResults,
  onSelect,
  setSearchResults,
  setSearchQuery,
}) => (
  <section className={classNames(styles["searchResult"])}>
    <ol className={classNames({ "is-hidden": !searchResults.length })}>
      {searchResults.slice(0, 10).map(result => (
        <SearchResultRow
          result={result}
          handleClick={() => {
            if (onSelect) onSelect(result)
            setSearchResults([])
            setSearchQuery("")
          }}
        />
      ))}
    </ol>
  </section>
)

export default SearchResults
