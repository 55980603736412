import React from "react"
import classNames from "classnames"

import Layout from "./Layout"
import Container from "./Container"
import ActionButtons from "elements/ActionButtons"

import styles from "./utils/layout.module.scss"

const CartLayout = props => (
  <Layout {...props}>
    <Container {...props}>
      <div className={classNames("columns is-centered", styles["container"])}>
        <div className="column pb-0-mobile is-7">{props.children}</div>
        <div
          className={classNames("column", {
            "is-hidden-mobile": !props.showCartInMobile,
            [styles["cart__containerIsHiddenTablet"]]: !props.showCartInTablet,
          })}
        >
          {props.cartContainer}
        </div>
      </div>
      <ActionButtons {...props} />
    </Container>
  </Layout>
)

export default CartLayout
