export const formatPrice = config => {
  let { priceString } = config
  let priceFloat = parseFloat(priceString)

  return priceFloat.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const generatePrice = medicine => {
  if (!medicine.priceList) return (0).toFixed(2)
  let vatex = medicine?.priceList?.vatex_unit_price || 0
  let vat = medicine?.priceList?.vat || 0
  return parseFloat(vatex + vat).toFixed(2)
}

export const accumulatePrice = medicines => {
  let total = 0
  medicines.forEach(medicine => {
    total +=
      medicine.qty * parseFloat(generatePrice(medicine)) -
      parseFloat(
        medicine?.discountValues?.reduce(
          (totalValue, currentValue) => totalValue + currentValue?.amount || 0,
          0
        ) || 0
      )
  })
  return total
}
