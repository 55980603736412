import React, { useContext } from "react"
import classNames from "classnames"
import { navigate } from "gatsby"

import DocumentsCardRow from "./DocumentsCard/DocumentsCardRow"
import CollapsibleCard from "elements/CollapsibleCard"
import Button from "elements/Button"

import { AppContext } from "../../context/AppContext"
import styles from "./utils/cart.module.scss"

const ErrorMessage = ({ epharmacyType }) => {
  let currentPath = window.location.pathname

  return (
    <section className="has-text-grey has-text-centered my-2">
      <p>You have no prescriptions in your list.</p>
      {!currentPath.includes("upload") && (
        <button
          className="button is-text"
          onClick={() =>
            navigate("/epharmacy/upload", {
              state: { epharmacyType: epharmacyType },
            })
          }
        >
          Upload Prescription
        </button>
      )}
    </section>
  )
}

const DocumentsCard = ({ title, location, epharmacyType }) => {
  const { dispatch, state } = useContext(AppContext)
  const { documents } = state
  const uploadPath = location?.pathname?.includes("upload")

  const handleDeleteDocument = index => {
    let tempDocuments = [...state?.documents]
    tempDocuments.splice(index, 1)
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...tempDocuments],
    })
  }

  const handleRedirectToUpload = () => {
    dispatch(
      {
        type: "HIDE_MODAL",
      },
      navigate("/epharmacy/upload", { state: { epharmacyType: epharmacyType } })
    )
  }

  return (
    <CollapsibleCard
      title={title || `Documents Uploaded (${documents.length})`}
      color="white"
      errorMessage={
        documents.length === 0 ? (
          <ErrorMessage epharmacyType={epharmacyType} />
        ) : null
      }
      isOpen={true}
    >
      <div className={classNames("mt-1", styles["cart"])}>
        {documents.map((file, index) => (
          <DocumentsCardRow
            key={index}
            fileName={file.name}
            oldFileName={file.oldname}
            index={index}
            handleDeleteDocument={handleDeleteDocument}
            file={file}
          />
        ))}
      </div>
      {!uploadPath && documents.length === 0 && (
        <Button
          className="is-text has-text-primary has-text-left"
          onClick={handleRedirectToUpload}
        >
          + add another prescription
        </Button>
      )}
    </CollapsibleCard>
  )
}

export default DocumentsCard
