import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"

import Cart from "./Cart"
import Dropzone from "elements/Dropzone"
import CartLayout from "../Layout/CartLayout"
import UploadGuidelines from "elements/UploadGuidelines"
import DocumentsCardRow from "elements/Dropzone/DocumentsCardRow"

import { AppContext } from "../../context/AppContext"

import {
  GATSBY_EPHARMACY_KEY,
  GATSBY_EPHARMACY_VALUE,
} from "gatsby-env-variables"

const Upload = ({ location }) => {
  const { state, dispatch } = useContext(AppContext)
  const { documents, epharmacy } = state
  const { medicines } = epharmacy
  let epharmacyType = location?.state?.epharmacyType

  const handleUploadSuccess = ({ file }) => {
    dispatch({
      type: "SAVE_DOCUMENT",
      payload: file,
    })
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_EPHARMACY_KEY) !== GATSBY_EPHARMACY_VALUE
    ) {
      navigate("/epharmacy/")
    }
  }, [])

  const handleDeleteDocument = index => {
    let tempDocuments = [...state?.documents]
    tempDocuments.splice(index, 1)
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...tempDocuments],
    })
  }

  return (
    <CartLayout
      cartContainer={
        <Cart epharmacyType={epharmacyType} open={{ documentsCard: true }} />
      }
      title="Upload Prescription"
      seoTitle="Upload Prescription"
      subtitle="Please upload your latest prescription."
      isCentered
      desktop={10}
      fullhd={8}
      back={{
        label: "Back",
        link: "/epharmacy/order",
        className: "is-hidden-desktop",
      }}
      next={{
        label: "Checkout",
        link: "/epharmacy/delivery-details",
        disabled: !state?.documents.length || medicines.length === 0,
        className: "is-hidden-desktop",
      }}
    >
      <UploadGuidelines />
      <div className="mb-1 is-hidden-desktop">
        {state?.documents?.length > 0 &&
          state?.documents?.map((document, index) => (
            <DocumentsCardRow
              index={index}
              fileName={document.name}
              oldFileName={document.oldname}
              handleDeleteDocument={handleDeleteDocument}
              file={document}
            />
          ))}
      </div>
      <Dropzone
        docType="rx"
        label="Upload Prescription"
        icon="prescription"
        maxFileCount={6}
        currentFilesList={documents}
        onUploadSuccess={handleUploadSuccess}
      />
    </CartLayout>
  )
}

export default Upload
