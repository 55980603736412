import React, { useContext, useEffect } from "react"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import Img from "gatsby-image"

import Layout from "layout/Layout"
import Container from "layout/Container"
import { Contact } from "../Elements/Contact"
import { ProgramName } from "../Elements/Brand"

import { AppContext } from "../../context/AppContext"

import {
  GATSBY_EPHARMACY_KEY,
  GATSBY_EPHARMACY_VALUE,
} from "gatsby-env-variables"

const Completed = () => {
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/complete__icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const complete = data.complete.childImageSharp.fluid
  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_EPHARMACY_KEY) !== GATSBY_EPHARMACY_VALUE
    ) {
      navigate("/epharmacy/")
    }

    dispatch({
      type: "RESET_STATE",
    })
  }, [dispatch])

  return (
    <Layout title="Thank you!" seoTitle="Submission Completed">
      <Container isCentered>
        <center>
          <Container isCentered mobile={8} tablet={6} desktop={4}>
            <Img fluid={complete} alt="Success!" />
          </Container>
          <p className="pb-1">
            Thank you for ordering from the <ProgramName />.
          </p>
          <p className="pb-1">
            Your order is currently being processed. You will receive an SMS
            confirming your order within one working day. The SMS will indicate
            the total quantity of medicines to be delivered and the total amount
            to be paid according to the financial assistance granted to you.
          </p>
          <p className="pb-3">
            Feel free to reach us at <Contact /> for any questions.
          </p>
          <Link to="/" className="button is-primary is-medium">
            Back to Home Page
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default Completed
