import React from "react"
import classNames from "classnames"

import { generatePrice } from "../../services/computations"

const MedicineInfoRow = ({ medicine }) => (
  <div className={classNames("mt-1 pb-1 has-border-bottom is-size-5")}>
    <div className="has-text-primary has-text-weight-bold">
      {medicine.productTitle}
    </div>
    <div className="columns is-mobile">
      <div className="column">
        <p>Quantity: {medicine.qty}</p>
        <p>Daily Intake: {medicine.intake}</p>
      </div>
      <div className={classNames("column has-text-right")}>
        Php{" "}
        {parseFloat(
          (generatePrice(medicine) * medicine.qty).toFixed(2)
        ).toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}
      </div>
    </div>
  </div>
)

export default MedicineInfoRow
