import React, { useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"

import Cart from "./Cart"
import Search from "../Search/Search"
import CartLayout from "../Layout/CartLayout"
import AddMedicineModal from "./Order/AddMedicineModal"

import { AppContext } from "../../context/AppContext"
import { useEpharmacyProducts } from "./hooks/useEpharmacyProducts"
import { isMedicineAlreadyInCart } from "./services/cart"
import MedicinesCard from "../Cart/MedicinesCard"

import {
  GATSBY_EPHARMACY_KEY,
  GATSBY_EPHARMACY_VALUE,
} from "gatsby-env-variables"

const Order = props => {
  let epharmacyType = props?.location?.state?.epharmacyType

  const data = useEpharmacyProducts(epharmacyType)
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines } = epharmacy

  const addMedicineToCart = async (item, qty, intake) => {
    const medicineList = [...state.epharmacy.medicines]
    medicineList.push({ ...item, qty: qty, intake: intake })
    await dispatch({
      type: "SAVE_MEDICINES",
      payload: medicineList,
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.molecule} to cart`,
        color: "success",
      },
    })
  }

  const handleOnSelect = item => {
    if (isMedicineAlreadyInCart(item, state.epharmacy.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${item.productTitle} already in cart`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal addToCart={addMedicineToCart} medicine={item} />
          ),
        },
      })
  }

  const searchComponentProps = {
    placeholder: "Search medicines (i.e. Ofev)",
    label: "Medicine Search",
    helper: {
      message:
        "Suggested Retail Price (SRP) does not yet reflect the financial assistance granted to you. Financial assistance will be applied when our pharmacist verifies your order.",
    },
    dataSource: {
      type: "graphql",
      data: data.nodes,
      lookupKeys: ["molecule", "brand"],
    },
    onSelect: handleOnSelect,
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_EPHARMACY_KEY) !== GATSBY_EPHARMACY_VALUE
    ) {
      navigate("/epharmacy/")
    }

    const productList = data.nodes
    if (medicines.length > 0 && productList.length === 1)
      navigate("/epharmacy/upload")
  }, [medicines, data, dispatch])

  return (
    <CartLayout
      cartContainer={
        <Cart
          epharmacyType={epharmacyType}
          open={{ documentsCard: true, medicinesCard: true }}
        />
      }
      title="Order Medicines"
      seoTitle="Order Medicines"
      subtitle="Search for medicines and add them to your cart."
      isCentered
      desktop={10}
      fullhd={8}
      back={{
        label: "Back",
        link: "/",
        className: "is-hidden-desktop",
      }}
      next={{
        label: "Upload Rx",
        link: "/epharmacy/upload",
        className: "is-hidden-desktop",
        disabled: medicines.length === 0,
      }}
    >
      <div className="mt-1">
        <div className="is-hidden-mobile">
          <Search {...searchComponentProps} />
        </div>
        <div className="is-hidden-desktop is-hidden-tablet">
          <Link to="/epharmacy/search" state={{ epharmacyType: epharmacyType }}>
            <Search type={epharmacyType} {...searchComponentProps} />
          </Link>
          <div className="mt-2">
            <MedicinesCard />
          </div>
        </div>
      </div>
    </CartLayout>
  )
}

export default Order
