import React from "react"
import classNames from "classnames"
import { get } from "lodash"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import summary from "../utils/summary.json"
import styles from "../utils/epharmacy.module.scss"

const DeliveryDetailsSummary = ({ epharmacy }) => {
  return summary.map(section => (
    <Section
      title={section.title}
      isRequired
      addOns={{
        right: <EditDetailsButton route={section.route} />,
      }}
    >
      <div className="table-container">
        <table class="table is-fullwidth is-size-5">
          <tbody>
            {section.fields.map(field => {
              if (get(epharmacy, field.key))
                return (
                  <tr>
                    <td className="has-text-weight-bold">{field.label}</td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {get(epharmacy, field.key)}
                    </td>
                  </tr>
                )
              return null
            })}
          </tbody>
        </table>
      </div>
    </Section>
  ))
}

export default DeliveryDetailsSummary
