import React from "react"
import classNames from "classnames"

import RxIndicator from "../../Elements/RxIndicator"

import {
  generatePrice,
  formatPrice,
} from "../../Epharmacy/services/computations"
import styles from "../utils/search.module.scss"

const MobileSearchResults = ({ searchResults, onSelect, searchDispatch }) => {
  const handleClick = result => {
    if (onSelect) onSelect(result)
    searchDispatch({ type: "SET_SEARCH_QUERY", payload: "" })
    searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
  }

  return (
    <section className={classNames(styles["mobile_search__results"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {searchResults.slice(0, 5).map(result => (
          <li>
            <a
              role="button"
              onClick={() => {
                handleClick(result)
              }}
            >
              <div className="is-flex has-text-weight-bold">
                <div>{result.productTitle}</div>
                {result.rxRequired && <RxIndicator />}
              </div>
              <div className="is-size-7">
                SRP: P{formatPrice({ priceString: generatePrice(result) })}
              </div>
            </a>
          </li>
        ))}
      </ol>
    </section>
  )
}

export default MobileSearchResults
