import { useStaticQuery, graphql } from "gatsby"

import { GATSBY_OFEV_HALF_BOX } from "gatsby-env-variables"

const EPHARMACY_PRODUCTS = graphql`
  {
    regularEpharmacyProducts: allProducts(
      filter: { forms_included: { in: "regular" } }
    ) {
      nodes {
        forms_included
        brand
        molecule
        productTitle
        rxRequired
        rxLevel
        category
        classification
        itemCode
        priceList {
          price_list
          vatex_unit_price
          vat
        }
      }
    }
    halfEpharmacyProducts: allProducts(
      filter: { forms_included: { in: ["regular", "half"] } }
    ) {
      nodes {
        brand
        molecule
        productTitle
        rxRequired
        rxLevel
        category
        classification
        itemCode
        priceList {
          price_list
          vatex_unit_price
          vat
        }
      }
    }
    blisterEpharmacyProducts: allProducts(
      filter: { forms_included: { in: ["regular", "blister"] } }
    ) {
      nodes {
        brand
        molecule
        productTitle
        rxRequired
        rxLevel
        category
        classification
        itemCode
        priceList {
          price_list
          vatex_unit_price
          vat
        }
      }
    }
    fullBlisterEpharmacyProducts: allProducts(
      filter: { forms_included: { in: ["regular", "half", "blister"] } }
    ) {
      nodes {
        brand
        molecule
        productTitle
        rxRequired
        rxLevel
        category
        classification
        itemCode
        priceList {
          price_list
          vatex_unit_price
          vat
        }
      }
    }
  }
`

export const useEpharmacyProducts = type => {
  const DATA = useStaticQuery(EPHARMACY_PRODUCTS)

  switch (type) {
    case "blister":
      return GATSBY_OFEV_HALF_BOX === "enabled"
        ? DATA.fullBlisterEpharmacyProducts
        : DATA.blisterEpharmacyProducts
    case "half":
      return DATA.halfEpharmacyProducts
    default:
      return DATA.regularEpharmacyProducts
  }
}
