import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Button from "elements/Button"
import DocumentsCard from "../Cart/DocumentsCard"
import MedicinesCard from "../Cart/MedicinesCard"

import styles from "./utils/epharmacy.module.scss"
import { AppContext } from "../../context/AppContext"

const Cart = ({ open, epharmacyType }) => {
  const [loading, setLoading] = useState(false)
  const { state } = useContext(AppContext)
  const { documents, epharmacy } = state
  const { medicines } = epharmacy
  const hasNoMeds = medicines.length === 0
  const hasNoDocuments = documents.length === 0
  const buttonText = hasNoMeds
    ? "Your medicine list cannot be empty."
    : hasNoDocuments
    ? "Your prescription list cannot be empty."
    : "Checkout"

  const handleRedirectToDeliveryDetails = () => {
    setLoading(true)
    navigate("/epharmacy/delivery-details")
  }

  return (
    <div className="mb-3">
      <h3 className="has-text-centered has-text-primary mt-0 is-hidden-mobile">
        My Cart
      </h3>
      <DocumentsCard
        title={`Prescriptions Uploaded (${documents.length})`}
        open={open?.documentsCard}
        epharmacyType={epharmacyType}
        // location={location}
      />
      <MedicinesCard epharmacyType={epharmacyType} open={open?.medicinesCard} />
      <div className={classNames("mt-2", styles["cart__checkoutButton"])}>
        <Button
          className={classNames(`button is-primary is-fullwidth mt-1`, {
            "has-background-grey": hasNoMeds || hasNoDocuments,
          })}
          disabled={hasNoMeds || hasNoDocuments}
          onClick={handleRedirectToDeliveryDetails}
          isLoading={loading}
        >
          <span className="is-size-6">{buttonText}</span>
        </Button>
      </div>
    </div>
  )
}

export default Cart
