import React from "react"
import { Router } from "@reach/router"

import Epharmacy from "components/Epharmacy/Epharmacy"
import Order from "components/Epharmacy/Order"
import Upload from "components/Epharmacy/Upload"
import DeliveryDetails from "components/Epharmacy/DeliveryDetails"
import Summary from "components/Epharmacy/Summary"
import Completed from "components/Epharmacy/Completed"
import SearchMedsMobile from "components/Epharmacy/SearchMedsMobile"
import BlisterMechanics from "../components/Blister/BlisterMechanics"
import HalfBox from "../components/HalfBox/HalfBox"

export default () => {
  return (
    <Router basepath="/epharmacy">
      <Epharmacy path="/" />
      <Order path="/order" />
      <Upload path="/upload" />
      <DeliveryDetails path="/delivery-details" />
      <Summary path="/summary" />
      <Completed path="/completed" />
      <SearchMedsMobile path="/search" />
      <BlisterMechanics path="/blister" />
      <HalfBox path="/half-box" />
    </Router>
  )
}
