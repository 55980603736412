import * as Yup from "yup"

import {
  REQUIRED_MESSAGE,
  INVALID_EMAIL,
  INVALID_MOBILE_NUMBER,
} from "../../../services/validations"

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  email: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]{9}$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  secondaryContactPerson: Yup.object().shape({
    fullName: Yup.string().required(REQUIRED_MESSAGE),
    mobileNumber: Yup.string()
      .min(11, INVALID_MOBILE_NUMBER)
      .max(11, INVALID_MOBILE_NUMBER)
      .matches(/^09[0-9]{9}$/, INVALID_MOBILE_NUMBER)
      .required(REQUIRED_MESSAGE),
  }),
  landline: Yup.string().matches(
    /^\d+$/,
    "Please enter a valid landline number"
  ),
  deliveryAddress: Yup.object().shape({
    addressType: Yup.string().required(REQUIRED_MESSAGE),
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    province: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    city: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
  paymentMethod: Yup.string().required(REQUIRED_MESSAGE),
  changeFor: Yup.string().when("paymentMethod", {
    is: "Cash on Delivery",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
})

export const medicineModalValidationSchema = Yup.object().shape(
  {
    dailyIntake: Yup.string().when("otherDailyIntake", {
      is: otherDailyIntake =>
        !otherDailyIntake || otherDailyIntake.length === 0,
      then: Yup.string().required(
        "Please fill out either one of these fields."
      ),
      otherwise: Yup.string(),
    }),
    otherDailyIntake: Yup.string().when("dailyIntake", {
      is: dailyIntake => !dailyIntake || dailyIntake.length === 0,
      then: Yup.string().required(
        "Please fill out either one of these fields."
      ),
      otherwise: Yup.string(),
    }),
  },
  [["dailyIntake", "otherDailyIntake"]]
)
