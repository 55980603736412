import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowLeft,
  faSearch,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/search.module.scss"

const MobileSearchInput = ({
  children,
  isLoading,
  backRoute,
  locationState,
}) => (
  <section className={classNames(styles["mobile_search"])}>
    <div className={classNames("is-flex", styles["mobile_search__container"])}>
      <Link to={backRoute} state={{ ...locationState }}>
        <button
          className={classNames("p-1-mobile", styles["mobile_search__button"])}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </Link>
      {children}
      <button
        className={classNames("p-1-mobile", styles["mobile_search__button"])}
      >
        <FontAwesomeIcon
          icon={isLoading ? faCircleNotch : faSearch}
          spin={isLoading}
        />
      </button>
    </div>
  </section>
)

export default MobileSearchInput
