import React from "react"
import RxIndicator from "elements/RxIndicator"
import {
  generatePrice,
  formatPrice,
} from "../../Epharmacy/services/computations"

const SearchResultRow = ({ result, handleClick }) => {
  const unitPrice = formatPrice({ priceString: generatePrice(result) })

  return (
    <li>
      <div
        onClick={() => handleClick()}
        onKeyDown={event => {
          if (event.key === "Enter") handleClick()
        }}
        role="button"
        tabIndex={0}
      >
        <section className="is-flex has-text-weight-bold">
          <div>{result.productTitle}</div>
          {result.rxRequired && <RxIndicator />}
        </section>
        <div className="is-size-7 mt-1">SRP: P{unitPrice}</div>
      </div>
    </li>
  )
}

export default SearchResultRow
