import React, { useContext } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import MedicinesCardRow from "./MedicinesCard/MedicinesCardRow"
import CollapsibleCard from "elements/CollapsibleCard"
import Button from "elements/Button"

import { AppContext } from "../../context/AppContext"
import { handleInputNumber } from "./services/cart"
import styles from "./utils/cart.module.scss"

const ErrorMessage = ({ epharmacyType }) => {
  let currentPath = window.location.pathname

  return (
    <section className="has-text-grey has-text-centered mt-2">
      <p>You have no medicines in your list.</p>
      {currentPath !== "/epharmacy/order" && (
        <button
          className="button is-text"
          onClick={() =>
            navigate("/epharmacy/order", {
              state: { epharmacyType: epharmacyType },
            })
          }
        >
          Add Medicines
        </button>
      )}
    </section>
  )
}

const MedicinesCard = ({ epharmacyType }) => {
  const { dispatch, state } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines } = epharmacy

  const PartnerRequestTag = () =>
    epharmacy?.hasPartnerRequest ? (
      <p className="has-text-left mt-1">
        <span className="tag is-primary">Partner Request</span>
      </p>
    ) : null

  const handleMedicineQtyChange = async (event, index) => {
    const medicineList = [...medicines]

    if (medicineList[index].molecule) {
      let { value } = event.target
      if (value.includes("e") || value.includes("-")) return
      value = handleInputNumber({ value, min: 1.0, max: 1000 })
      medicineList[index].qty = value
      await dispatch({ type: "SAVE_MEDICINES", payload: medicineList })
    }
  }

  const handleDeleteMedicine = async index => {
    const medicineList = [...medicines]
    medicineList.splice(index, 1)
    await dispatch({ type: "SAVE_MEDICINES", payload: medicineList })
  }

  const handleRedirectToSearch = () => {
    dispatch(
      {
        type: "HIDE_MODAL",
      },
      navigate("/epharmacy/order")
    )
  }

  return (
    <CollapsibleCard
      title={`Medicines Ordered (${medicines.length})`}
      color="white"
      errorMessage={
        medicines.length === 0 ? (
          <ErrorMessage epharmacyType={epharmacyType} />
        ) : null
      }
      isOpen={true}
    >
      <div>
        <PartnerRequestTag />
        <ol className={classNames(styles["cart"], "has-text-left")}>
          {medicines.map((medicine, index) => (
            <MedicinesCardRow
              key={index}
              medicine={medicine}
              index={index}
              handleDeleteMedicine={handleDeleteMedicine}
              handleMedicineQtyChange={handleMedicineQtyChange}
            />
          ))}
        </ol>
        {medicines.length === 0 && (
          <p className="has-text-left">
            <Button
              className="is-text has-text-primary"
              onClick={handleRedirectToSearch}
            >
              + add more medicines
            </Button>
          </p>
        )}
      </div>
    </CollapsibleCard>
  )
}

export default MedicinesCard
