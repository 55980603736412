import React from "react"

import { usePaymentMethodImages } from "../../hooks/usePaymentMethodImages"
import RadioContainer from "./RadioContainer"

const PaymentMethod = ({ paymentMethod, setFieldValue, values }) => {
  const data = usePaymentMethodImages()
  const handleClick = () => {
    setFieldValue("paymentMethod", paymentMethod?.label)
  }

  return (
    <RadioContainer
      isActive={values.paymentMethod === paymentMethod.label}
      onClick={handleClick}
      logo={data[paymentMethod.image]?.childImageSharp.fixed}
    >
      <p className="has-text-centered">{paymentMethod.label}</p>
    </RadioContainer>
  )
}

export default PaymentMethod
