import React, { useRef, useState, Fragment, useContext, useEffect } from "react"
import { navigate } from "gatsby"

import MobileSearch from "../Search/MobileSearch"
import AddMedicineSection from "./SearchMedsMobile/AddMedicineSection"

import { AppContext } from "../../context/AppContext"
import { isMedicineAlreadyInCart } from "./services/cart"
import { useEpharmacyProducts } from "./hooks/useEpharmacyProducts"
import AddMedicineModal from "./Order/AddMedicineModal"

import {
  GATSBY_EPHARMACY_KEY,
  GATSBY_EPHARMACY_VALUE,
} from "gatsby-env-variables"

const SearchMedsMobile = ({ location }) => {
  let epharmacyType = location?.state?.epharmacyType
  const [selectedMedicine, setSelectedMedicine] = useState(null)
  const { state, dispatch } = useContext(AppContext)
  const inputRef = useRef({ current: { value: "" } })
  const data = useEpharmacyProducts(epharmacyType)

  const handleOnSelect = medicine => {
    if (isMedicineAlreadyInCart(medicine, state.epharmacy.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${medicine.productTitle} already in cart`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal
              addToCart={addMedicineToCart}
              medicine={medicine}
            />
          ),
        },
      })
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_EPHARMACY_KEY) !== GATSBY_EPHARMACY_VALUE
    ) {
      navigate("/epharmacy/")
    }
  }, [])

  const searchComponentProps = {
    placeholder: "Search medicines (i.e. Ofev)",
    label: "Medicine Search",
    helper: {
      message:
        "Suggested Retail Price (SRP) does not yet reflect the financial assistance granted to you. Financial assistance will be applied when our pharmacist verifies your order.",
    },
    dataSource: {
      type: "graphql",
      data: data.nodes,
      lookupKeys: ["molecule", "brand"],
    },
    onSelect: handleOnSelect,
    inputRef: inputRef,
    backRoute: "/epharmacy/order",
  }

  const addMedicineToCart = async (item, qty, intake) => {
    const medicineList = [...state.epharmacy.medicines]
    medicineList.push({ ...item, qty: qty, intake: intake })
    await dispatch({
      type: "SAVE_MEDICINES",
      payload: medicineList,
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.molecule} to cart`,
        color: "success",
      },
    })
  }

  return (
    <Fragment>
      <MobileSearch {...searchComponentProps} />
      {selectedMedicine?.productTitle && (
        <AddMedicineSection
          addMedicineToCart={addMedicineToCart}
          setSelectedMedicine={setSelectedMedicine}
          selectedMedicine={selectedMedicine}
        />
      )}
    </Fragment>
  )
}

export default SearchMedsMobile
