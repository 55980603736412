import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import MedsOrderedSummary from "./Summary/MedsOrderedSummary"
import OrderSubtotalSummary from "./Summary/OrderSubtotalSummary"
import DeliveryDetailsSummary from "./Summary/DeliveryDetailsSummary"
import UploadedDocumentsSummary from "./Summary/UploadedDocumentsSummary"

import { sendOrder } from "./services/order"
import { AppContext } from "../../context/AppContext"

import {
  GATSBY_EPHARMACY_KEY,
  GATSBY_EPHARMACY_VALUE,
} from "gatsby-env-variables"

const Summary = () => {
  const [loading, setLoading] = useState(false)
  const { state } = useContext(AppContext)
  const { epharmacy, documents } = state
  const { medicines } = epharmacy

  const layoutSubtitle = (
    <span>
      Please review if the following information is correct.
      <br className="is-hidden-mobile" /> Your information will be used as a
      basis for your delivery.
    </span>
  )

  const handleSuccessCallback = () => {
    setLoading(false)
    navigate("/epharmacy/completed")
  }

  const handleSubmitError = ({ message }) => {
    setLoading(false)
  }

  const handleOnSubmit = () => {
    setLoading(true)
    sendOrder({
      epharmacy,
      documents,
      callback: handleSuccessCallback,
      errorCallback: handleSubmitError,
    })
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_EPHARMACY_KEY) !== GATSBY_EPHARMACY_VALUE
    ) {
      navigate("/epharmacy/")
    }
  }, [])

  return (
    <Layout
      title="Order Summary"
      seoTitle="Order Summary"
      subtitle={layoutSubtitle}
    >
      <Container isCentered>
        <DeliveryDetailsSummary epharmacy={epharmacy} />
        <MedsOrderedSummary medicines={medicines} />
        <OrderSubtotalSummary medicines={medicines} />
        <UploadedDocumentsSummary files={documents} route="/epharmacy/upload" />
        <Message color="warning">
          <p className="is-size-6 has-text-dark">
            Our pharmacist will confirm the medicine quantity and price through
            SMS. Orders are not final until our pharmacist verifies your order.
          </p>
        </Message>
        <ActionButtons
          back={{ label: "Back", link: "/epharmacy/delivery-details" }}
          submit={{
            label: "Place Order",
            handleClick: handleOnSubmit,
            loading,
          }}
        />
      </Container>
    </Layout>
  )
}

export default Summary
