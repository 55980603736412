import React, { useContext, useEffect } from "react"
import { Form, Formik } from "formik"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"

import { AppContext } from "../../context/AppContext"
import { generateFormField } from "../Elements/Form/services/form"
import deliveryDetailsForm from "./utils/deliveryDetailsForm.json"
import { validationSchema } from "./utils/deliveryDetailsFormSchema"
import PaymentMethodSection from "./DeliveryDetails/PaymentMethodSection"

import {
  GATSBY_EPHARMACY_KEY,
  GATSBY_EPHARMACY_VALUE,
} from "gatsby-env-variables"

const DeliveryDetails = () => {
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy } = state

  const layoutSubtitle = (
    <span>
      <b>Globo Asiatico Enterprises Inc. will be delivering your medicines. </b>
      <br className="is-hidden-mobile" />
      Please answer the following questions accurately. Your information will be
      used as a basis for your delivery.
    </span>
  )

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_EPHARMACY_KEY) !== GATSBY_EPHARMACY_VALUE
    ) {
      navigate("/epharmacy/")
    }
  }, [])

  const handleSubmit = values => {
    dispatch({
      type: "SAVE_DELIVERY_DETAILS",
      payload: { ...values },
    })
    navigate("/epharmacy/summary")
  }

  return (
    <Layout
      title="Delivery Details"
      seoTitle="Delivery Details"
      subtitle={layoutSubtitle}
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...epharmacy }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {deliveryDetailsForm?.map(section => (
                <Section {...section}>
                  {section?.fields?.map(field => {
                    return generateFormField({
                      formFields: section?.fields,
                      formField: field,
                      values,
                      setFieldValue,
                    })
                  })}
                </Section>
              ))}
              <PaymentMethodSection
                values={values}
                setFieldValue={setFieldValue}
              />
              <Message color="warning">
                <p className="is-size-6 has-text-dark">
                  Our pharmacist will confirm the medicine quantity and price
                  through SMS. Orders are not final until our pharmacist
                  verifies your order.
                </p>
              </Message>
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ActionButtons
                submit={{
                  label: "Next",
                }}
                back={{
                  label: "Back",
                  link: "/epharmacy/upload",
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default DeliveryDetails
