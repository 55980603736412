import React from "react"

export const paymentMethods = [
  {
    label: "Bank Transfer or Deposit",
    image: "bank",
    isNationwide: true,
    followUps: [
      {
        followUpType: "message",
        message: (
          <div className="is-size-6">
            <p className="is-size-6">BDO Savings</p>
            <p className="is-size-6">
              Account name: Globoasiatico Enterprises, Inc.
            </p>
            <p className="is-size-6">Account number: 003560067329</p>
            <p className="is-size-6">Branch: Maginhawa</p>
          </div>
        ),
      },
      {
        followUpType: "message",
        message: (
          <div className="is-size-6">
            <p className="is-size-6">BPI Current</p>
            <p className="is-size-6">
              Account name: Globoasiatico Enterprises, Inc.
            </p>
            <p className="is-size-6">Account number: 1991-0061-13</p>
            <p className="is-size-6">Branch: Kalayaan</p>
          </div>
        ),
      },
    ],
  },
]
