import { useStaticQuery, graphql } from "gatsby"

const ALL_PAYMENT_METHOD_IMAGES = graphql`
  {
    cod: file(relativePath: { eq: "payment-details/payment_cod.png" }) {
      childImageSharp {
        fixed(width: 70, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bank: file(
      relativePath: { eq: "payment-details/payment_bank-transfer.png" }
    ) {
      childImageSharp {
        fixed(width: 70, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    check: file(
      relativePath: { eq: "payment-details/payment_cheque--square.png" }
    ) {
      childImageSharp {
        fixed(width: 70, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const usePaymentMethodImages = () => {
  const data = useStaticQuery(ALL_PAYMENT_METHOD_IMAGES)

  return data
}
