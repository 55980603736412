import React from "react"

import OrderTotal from "./OrderSubtotalSummary/OrderTotal"

import { accumulatePrice } from "../services/computations"

const OrderSubtotalSummary = ({ medicines }) => {
  return (
    <div className="px-1 mb-3">
      <OrderTotal totalPrice={accumulatePrice(medicines).toFixed(2)} />
    </div>
  )
}

export default OrderSubtotalSummary
