import { generatePrice } from "../../services/computations"

export const epharmacyOrderZendeskTemplate = epharmacy => {
  let {
    firstName,
    lastName,
    middleInitial,
    email,
    mobileNumber,
    landline,
    secondaryContactPerson,
    deliveryAddress,
    paymentMethod,
    medicines,
    changeFor,
  } = epharmacy

  let medicinesOrdered = ""

  medicines.forEach(medicine => {
    medicinesOrdered += `${medicine.productTitle} #${
      medicine.qty
    }, Price: ₱${generatePrice(medicine)}, intake: ${medicine.intake}\n`
  })

  return `First name: ${firstName}\nLast name: ${lastName}\nMiddle initial: ${middleInitial ||
    "N/A"}\nEmail: ${email}\nMobile number: ${mobileNumber}\nLandline: ${landline ||
    "N/A"}\nSecondary contact person: ${
    secondaryContactPerson.fullName
  }\nMobile number of contact person: ${
    secondaryContactPerson.mobileNumber
  }\nPayment method: ${paymentMethod}\nChange for: ${changeFor ||
    "N/A"}\n---\nMedicines Ordered\n\n${medicinesOrdered}\n---\nDelivery Address\nStreet address: ${
    deliveryAddress?.streetAddress
  }\nCity/Municipality: ${deliveryAddress?.city.value}\nProvince: ${
    deliveryAddress?.province.value
  }\nAddress type: ${
    deliveryAddress?.addressType
  }\nDelivery notes: ${deliveryAddress?.notes || "N/A"}`
}
