import React from "react"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

const CollapsibleCard = ({
  title,
  children,
  isOpen,
  color,
  className,
  errorMessage,
}) => (
  <article
    className={classNames(className, `message mb-1`, {
      [`is-${color}`]: !!color,
    })}
  >
    <details
      open={isOpen}
      className={classNames(`message-body p-0`, styles["message__collapsible"])}
    >
      <summary
        className={classNames(
          styles["collapsible__title"],
          "has-background-light has-text-centered has-text-primary"
        )}
      >
        {title}
      </summary>
      <div className={classNames(`px-1`, styles["collapsible__content"])}>
        {errorMessage ? errorMessage : children}
      </div>
    </details>
  </article>
)

export default CollapsibleCard
