import { GATSBY_ENV } from "gatsby-env-variables"

import {
  generateTemplate,
  sendToZendesk,
} from "../../../services/zendeskService"
import { isPdf } from "../../Elements/services/dropzone"
import { epharmacyOrderZendeskTemplate } from "../utils/templates/epharmacyOrderZendeskTemplate"
import { handleError } from "../../../services/handleError"

export const sendOrder = async ({
  epharmacy,
  documents,
  callback,
  errorCallback,
}) => {
  try {
    let fileArray = [...documents]
    let subject = "Order from"
    let tags = ["bm_hope", "order"]
    if (GATSBY_ENV !== "production") tags.push("test")

    const requestTemplate = generateTemplate({
      subject,
      email: epharmacy?.email,
      template: epharmacyOrderZendeskTemplate,
      templateObjects: epharmacy,
      tags,
      name: `${epharmacy?.firstName} ${epharmacy?.lastName}`,
    })

    fileArray = fileArray.map(file => {
      if (isPdf(file.path)) {
        return {
          ...file,
          filename: `${file.filename}.pdf`,
          name: `${file.name}.pdf`,
        }
      }
      return file
    })
    await sendToZendesk(requestTemplate, fileArray)
    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}
