import React from "react"
import classNames from "classnames"
import { ErrorMessage } from "formik"

import Section from "elements/Section"
import PaymentMethod from "./PaymentMethodSection/PaymentMethod"

import styles from "../utils/epharmacy.module.scss"
import { generateFollowUp } from "./utils/paymentMethod"
import { paymentMethods } from "../utils/paymentMethods"

const PaymentMethodSection = ({ values, setFieldValue }) => {
  let { deliveryAddress } = values
  let { province } = deliveryAddress
  const isNationwide = province !== "Metro Manila"

  return (
    <Section title="Payment Method" isRequired>
      {paymentMethods?.map(paymentMethod => {
        if (isNationwide && !paymentMethod.isNationwide) return null
        return (
          <div
            className={classNames(
              "is-flex",
              styles["shippingDetails__shippingOptions"]
            )}
          >
            <PaymentMethod
              paymentMethod={paymentMethod}
              setFieldValue={setFieldValue}
              values={values}
            />
            {values.paymentMethod === paymentMethod.label &&
              paymentMethod?.followUps?.map(followUp => (
                <div
                  className={classNames(
                    "notification has-background-light mb-1 mx-1 mx-1-mobile",
                    styles["paymentMethod__followUp"]
                  )}
                >
                  {generateFollowUp({ followUp })}
                </div>
              ))}
          </div>
        )
      })}
      <p className="help mt-0 mb-1 ml-1 is-danger">
        <ErrorMessage name="paymentMethod" />
      </p>
    </Section>
  )
}

export default PaymentMethodSection
