import React, { Fragment } from "react"
import { generatePrice } from "../services/computations"
import RxIndicator from "../../Elements/RxIndicator"

import Message from "elements/Message"

const MedicineInfo = ({ medicine }) => (
  <Fragment>
    <h5 className="has-text-primary has-text-centered mt-0">
      {medicine.productTitle}
      {medicine.rxRequired && <RxIndicator />}
    </h5>
    <Message color="warning" className="mt-1 is-small has-text-left">
      <span className="has-text-black is-size-7">
        Suggested Retail Price (SRP) does not yet reflect the financial
        assistance granted to you. Financial assistance will be applied when our
        pharmacist verifies your order.
      </span>
    </Message>
    <p className="has-text-left has-text-grey is-size-6">
      Quantity you want to purchase:
    </p>
    <p className="has-text-left has-text-grey is-size-6">
      Unit Price: Php{" "}
      {parseFloat(generatePrice(medicine)).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}
    </p>
  </Fragment>
)

export default MedicineInfo
