import React from "react"
import classNames from "classnames"

const OrderTotal = ({ totalPrice }) => (
  <div className={classNames("is-size-5 has-text-grey has-text-left mt-1")}>
    Order Total:{" "}
    <span className="ml-1 has-text-black has-text-weight-bold">
      P{" "}
      {parseFloat(totalPrice).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}
    </span>
    <br />
    <span className="is-size-6">
      Suggested Retail Price (SRP) does not yet reflect the financial assistance
      granted to you. Financial assistance will be applied when our pharmacist
      verifies your order.
    </span>
  </div>
)

export default OrderTotal
