import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

import FormInput from "elements/Form/FormInput"
import Button from "elements/Button"

import styles from "../utils/epharmacy.module.scss"

const MedicineQuantityInput = ({ setFieldValue, qty, changeQuantity }) => (
  <FormInput
    hasAddons
    addonLeft={
      <Button
        onClick={() => changeQuantity(setFieldValue, parseInt(qty) - 1)}
        color="danger"
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
    }
    addonRight={
      <Button
        onClick={() => changeQuantity(setFieldValue, parseInt(qty) + 1)}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    }
    name="qty"
    inputmode="numeric"
    className={classNames("has-text-centered", styles["numberInput"])}
    type="number"
    pattern="[0-9]"
    min={1}
    max={10}
    hideOptional
    onKeyPress={event => {
      if (isNaN(event.key)) event.preventDefault()
    }}
  />
)

export default MedicineQuantityInput
